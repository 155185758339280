.eventList{
    width: 26.188rem;
    height: 32.25rem;
    background: #2A2A2A;
    box-shadow: 0px 0px 62px rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 20px 40px;
    margin: 0;
    flex: 2;
    min-width: 25rem;
    max-width: 27.5rem;
    height: fit-content;
}

.eventList .col{
    height: 6.875rem;
    padding: 5px;
}

@media only screen and (max-width:420px){
    .eventList h1{
        font-size: 24px;
    }
    .eventList h3{
        font-size: 20px;
    }
    .eventList p{
        font-size: 14px;
    }
    .eventList{
        min-width: 18rem;
        padding: 12px 24px;
    }
}
