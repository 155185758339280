@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
body{
  font-family: 'Gilroy', sans-serif;
  background-color: #1E1E1E;
  color: white; 
  margin: 0;
  padding: 0;
}


.overlay{
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #1E1E1E;
  opacity: 0.4;
  z-index: 9;
  position:fixed;
  padding:0;
  margin:0;
  top:0;
  left:0;
}


h1{
  margin-left: 5%;
  font-size: 32px;
  font-weight: 700;
}

.story{
  margin: 0 5%;
  margin-bottom: 16px;
}

footer{
  background: #2A2A2A;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 62px;
}

footer div{
  padding: 10px;
}

footer button{
  border: 1.5px solid #ffffff41;
  background: none;
  border-radius: 50%;
  padding: 5px;
  margin: 5px;
  width: 35px;
  height: 35px;
  color: white;
  margin-bottom: 30px;
}

footer i{
  /* width: 24px; */
  font-size: 16px;
  padding-top: 2px;
}

footer>div :nth-child(1){
  flex:3;

}

footer>div:nth-child(2){
  flex: 5;

}

footer>div:nth-child(3){
  flex: 4;

}

.mobileFoot{
  display: none;
}
.webFoot{
  display: block;
}

.subscribe{
  margin-top: 56px;
  background: linear-gradient(180deg, #2A2A2A 0%, rgba(42, 42, 42, 0) 100%);
  padding: 1.75rem 4.5rem;
}

.subscribe>p{
  width: 34.375rem;
  font-size: 18px;
}

.subscribe>h1{
  margin: 0;
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 700;
}

.subscribe>form{
  width: 22.5rem;
}
.form-control,.form-control:focus{
  background-color: #2A2A2A;
  border: none;
  color: white;
  box-shadow: none;
}

.submit{
  width: 8.313rem;
  height: 2.5rem;
  border-radius: 8px;
  border: none;
  margin: 0px;
  font-weight: bold;
  background: #FFFC00;
  color: black;
}

.modalCross{
  position: absolute;
  background: rgba(113, 113, 113, 0.5);
  right: 10px;
  top: 10px;
  z-index: 100;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  text-align: center;
  padding-top: 0.5rem;
  cursor: pointer;
}

@media only screen and (max-width:1020px) {
  
  footer>div{
    flex:5;
  }
  .mobileFoot{
    display: block;
  }
  .webFoot{
    display: none;
  }
}

@media only screen and (max-width:600px) {
  footer{
    padding:14px;
    font-size: 10px;
  }
  footer>div{
    flex:6;
  }
  .subscribe>p{
    width: 18.75rem;
  }
  .subscribe>form{
    width: 18.125rem;
  }
}

@media only screen and (max-width:440px) {
  .subscribe{
    padding-left: 5%;
  }
}