.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: slategray;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.swiper-button-prev, .swiper-button-next{
  width: 4.125rem !important;
  height: 4.125rem !important;
}

.swiper-button-next{
  background-image: url("../../Assets/nextBtn.svg") !important;
}
.swiper-button-next::after{
  content: "" !important;
}
.swiper-button-prev{
  background-image: url("../../Assets/prevBtn.svg") !important;
}
.swiper-button-prev::after{
  content: "" !important;
}

.swiper-pagination-bullet{
  /* color: white !important; */
  background-color: white;
  width: 14px !important;
  height: 14px !important;
}

