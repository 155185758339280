@font-face {
  font-family: "Gilroy";
  src: url("Assets/fonts/Gilroy-Black.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("Assets/fonts/Gilroy-Bold.ttf");
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: "Gilroy";
  src: url("Assets/fonts/Gilroy-ExtraBold.ttf");
  font-weight: 800;
  font-style: bolder;
}

@font-face {
  font-family: "Gilroy";
  src: url("Assets/fonts/Gilroy-SemiBold.ttf");
  font-weight: 500;
  font-style: bold;
}

@font-face {
  font-family: "Gilroy";
  src: url("Assets/fonts/Gilroy-Medium.ttf");
  font-weight: 400;
  font-style: bold;
}

@font-face {
  font-family: "Gilroy";
  src: url("Assets/fonts/Gilroy-Light.ttf");
  font-weight: 200;
  font-style: light;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.sticky{
  background-color: #121212;
  transition: all 0.3s ease;
}