.footerCaption{
    position: relative;
}

.arrow{
    cursor: pointer;
    background-size: contain;
    position: fixed;
    bottom:2rem;
    right:0;
    z-index: 10000;
}
.logoDiv{
    font-size: 16px;
    font-weight: 700;
}

@media only screen and (max-width:600px){
    .socialBtns{
        margin-top: 10px;
    }
    .socialBtns button{
        margin-left: 0;
    }
}

@media only screen and (max-width:600px){
    .socialBtns button{
        width: 30px;
        height: 30px;
        margin-right: 3px;
    }
    .socialBtns i{
        font-size: 12px;
    }
}