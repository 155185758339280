.newsCard{
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    margin-left: 0;
    flex: 2;
    min-width: 220px;
}

.newsCard img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
}