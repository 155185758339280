.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background-color: slategray;


  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}
.swiper-button-next,
.swiper-button-prev{
    color: black;
}
.swiper-pagination-bullet{
    background-color: black;
}

.caraousel-div{
  font-family: "Gilroy";
  position: fixed;
  /* top: 45%; */
  bottom: 10%;
  left: 10%;
  text-align: left;
  font-size: 18px;
  width: 26.875rem;
}

.caraousel-div a{
  text-decoration: none;
  color: white;
}

.caraousel-div h2{
  font-size: 64px;
  width: 45rem;
  min-width: 20rem;
  font-weight: 600;
}

.caraousel-div p{
  margin-bottom: 50px;
  width: 35rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 3rem;
  margin-bottom: 20px;
}

.caraousel-div button{
  width: 10.5rem;
  height: 3rem;
  border-radius: 8px;
  border: none;
  margin: 5px;
  font-weight: bold;
}

.caraousel-div .ticket{
  font-weight: 500;
  background: #FFFC00;
  color: black;
}

.caraousel-div .trailer{
  font-weight: 500;
  background: rgba(113, 113, 113, 0.5);
  color: white;
}

@media only screen and (max-width:600px){
  .caraousel-div h2{
    font-size: 48px;
  }
  .caraousel-div p{
    width: 20rem;
  }
  .caraousel-div .ticket{
    width: 6.25rem;
  }
  .caraousel-div .trailer{
    width: 8.875rem;
  }
  .caraousel-div button{
    font-size: 14px;
  }
}

@media only screen and (max-width:380px){
  .caraousel-div h2{
    font-size: 36px;
  }
  .caraousel-div p{
    width: 16rem;
  }
}