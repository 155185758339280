.eventsCard{
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
    margin-left: 0;
    flex: 3;
    min-width: 18rem;
}

.eventsCard img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
}