.card{
    width: 42rem;
    height: 90vh;
    background-color: #121212;
     position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
    border-radius: 8px;
}

.card iframe{
    width: 100%;
    height: 45%;
    border-radius: 8px 8px 0px 0px;
}
.card>div{
    margin-left: 5%;
    width: 90%;
    text-align: justify;
    margin-top: 24px;
}
.card>div>h1{
    margin-left: 0;
}
.card>div>p{
    font-size: 18px;
}

.card button{
    width: 10.5rem;
    height: 3rem;
    border-radius: 8px;
    border: none;
    margin: 5px;
    font-weight: bold;
}

.card .ticket{
    font-weight: 500;
    background: #FFFC00;
    color: black;
}

.card .trailer{
    font-weight: 500;
    background: rgba(113, 113, 113, 0.5);
    color: white;
}

@media only screen and (max-width:890px){
    .card{
        width: 30rem;
    }
}
@media only screen and (max-width:580px){
    .card{
        width: 20rem;
    }
}
@media only screen and (max-width:460px){
    .card{
        width: 17rem;
        height: 80vh;
    }
}